import { GwtDeeplinkParam } from '../../../model/gwt-deeplink-param';
import { GwtDeeplinkStrategy } from './gwt-deeplink-strategy';

export class InsuranceUpdateDeeplinkStrategy implements GwtDeeplinkStrategy {
  static BUSINESS_ID = 'bId';
  static INSURANCE_UPDATE_SERVICE = 'insurance-update-service:insurance-update-service:main';

  urlParams: URLSearchParams;

  matchDeeplink(urlParams: URLSearchParams): boolean {
    this.urlParams = urlParams;

    return this.urlParams.get(GwtDeeplinkParam.PLACE_NAME) === InsuranceUpdateDeeplinkStrategy.INSURANCE_UPDATE_SERVICE;
  }

  resolveUrl(urlOrigin: string): string {
    return `${urlOrigin}/#/insurance/main-panel-admin/${this.urlParams.get(InsuranceUpdateDeeplinkStrategy.BUSINESS_ID)}/update`;
  }
}
