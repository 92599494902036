import { GwtDeeplinkParam } from '../../../model/gwt-deeplink-param';
import { GwtDeeplinkStrategy } from './gwt-deeplink-strategy';

export class NoParamsDeeplinkStrategy implements GwtDeeplinkStrategy {

  private matchedUrl: string;

  private gwtDeeplinkOrchestratorUrl = new Map([
    [ 'home:main', '/#/dashboard' ],
    [ 'settings:main', '/#/dashboard/user-settings' ],
    [ 'fx-posted-freights:main', '/#/fx-ui/posted-freights' ],
    [ 'fx-posted-vehicles:main', '/#/fx-ui/posted-vehicles' ],
    [ 'fx-post-freight:main', '/#/fx-ui/posted-freights/create' ],
    [ 'fx-post-vehicle:main', '/#/fx-ui/posted-vehicles/create' ],
    [ 'fx-freight-searches:main', '/#/fx-ui/freight-searches' ],
    [ 'fx-vehicle-searches:main', '/#/fx-ui/vehicle-searches' ],
    [ 'fx-search-freights:main', '/#/fx-ui/freight-searches/new-freight-search' ],
    [ 'search:create', '/#/fx-ui/freight-searches/new-freight-search' ],
    [ 'fx-search-vehicles:main', '/#/fx-ui/vehicle-searches/new-vehicle-search' ],
    [ 'consulted-freights:main', '/#/fx-ui/consulted-freights' ],
    [ 'consulted-vehicles:main', '/#/fx-ui/consulted-vehicles' ],
    [ 'history-posted-freight:main', '/#/fx-ui/history-freights' ],
    [ 'history-posted-vehicle:main', '/#/fx-ui/history-vehicles' ],
    [ 'recommended-offer-selected-for-you:main', '/#/fx-ui/recommended-searches/selected-for-you' ],
    [ 'recommended-offer-trending-now:main', '/#/fx-ui/recommended-searches/trending-now' ],
    [ 'recommended-offer-opportunities:main', '/#/fx-ui/recommended-searches/opportunities' ],
    [ 'fx-list-contact:main', '/#/fx-ui/address-book' ],
    [ 'placed-quotes:main', '/#/fx-ui/placed-quotes' ],
    [ 'fx-sms-history:main', '/#/fx-ui/sms-history' ],
    [ 'terms-conditions-signed:main', '/#/terms-conditions/signed' ],
    [ 'insurance-service:main', '/#/insurance/main-panel' ],
    [ 'invoice-factoring-service:main', '/#/invoice-factoring' ],
  ]);

  matchDeeplink(urlParams: URLSearchParams): boolean {
    const placeParam = urlParams.get(GwtDeeplinkParam.PLACE_NAME);

    this.matchedUrl = this.gwtDeeplinkOrchestratorUrl.get(placeParam);

    return !!this.matchedUrl;
  }

  resolveUrl(urlOrigin: string): string {
    return `${urlOrigin}${this.matchedUrl}`;
  }
}
