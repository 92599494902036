export enum App {
  CHAT = '@alpega/chat',
  COMPANY_PROFILE = '@alpega/company-profile-ui',

  FX_UI = '@alpega/fx-ui',
  INSURANCE = '@alpega/insurance',
  INVOICE_FACTORING_PRAGMAGO = '@alpega/invoice-factoring-pragmago',
  INVOICE_FACTORING = '@alpega/invoice-factoring',

  MARKETPLACE_AUTH_UI = '@alpega/marketplace-auth-ui',
  MARKETPLACE_DASHBOARD_UI = '@alpega/marketplace-dashboard-ui',
  MARKETPLACE_FOOTER_UI = '@alpega/marketplace-footer-ui',
  MARKETPLACE_HEADER_UI = '@alpega/marketplace-header-ui',
  MARKETPLACE_STYLEGUIDE = '@alpega/marketplace-styleguide-ui',

  TELEROUTE_DIRECTORY = '@alpega/teleroute-directory',

  TERMS_CONDITIONS = '@alpega/terms-conditions',
  TERMS_CONDITIONS_NO_ROUTER = '@alpega/terms-conditions-no-router',
}
