// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../webfonts/fontawesome-regular-400.eot";
import ___CSS_LOADER_URL_IMPORT_1___ from "../webfonts/fontawesome-regular-400.woff2";
import ___CSS_LOADER_URL_IMPORT_2___ from "../webfonts/fontawesome-regular-400.woff";
import ___CSS_LOADER_URL_IMPORT_3___ from "../webfonts/fontawesome-regular-400.ttf";
import ___CSS_LOADER_URL_IMPORT_4___ from "../webfonts/fontawesome-regular-400.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#fontawesome" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:'Font Awesome 5 Regular';font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"svg\")}.far{font-family:'Font Awesome 5 Regular';font-weight:400}\n", "",{"version":3,"sources":["webpack://./src/assets/fontawesome/css/font-awesome-regular.css"],"names":[],"mappings":"AAAA,WAAW,oCAAoC,CAAC,iBAAiB,CAAC,eAAe,CAAC,2CAAkD,CAAC,uSAAgW,CAAC,KAAK,oCAAoC,CAAC,eAAe","sourcesContent":["@font-face{font-family:'Font Awesome 5 Regular';font-style:normal;font-weight:400;src:url(\"../webfonts/fontawesome-regular-400.eot\");src:url(\"../webfonts/fontawesome-regular-400.eot?#iefix\") format(\"embedded-opentype\"),url(\"../webfonts/fontawesome-regular-400.woff2\") format(\"woff2\"),url(\"../webfonts/fontawesome-regular-400.woff\") format(\"woff\"),url(\"../webfonts/fontawesome-regular-400.ttf\") format(\"truetype\"),url(\"../webfonts/fontawesome-regular-400.svg#fontawesome\") format(\"svg\")}.far{font-family:'Font Awesome 5 Regular';font-weight:400}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
