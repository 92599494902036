import * as singleSpa from 'single-spa';

import AppMain from '../../model/app-main';

import { AppS3Folder } from '@alpegagroup/ui-core-shared/lib/constant/AppS3Folder';
import { AppS3Subfolder } from '@alpegagroup/ui-core-shared/lib/constant/AppS3Subfolder';
import BundleLoaderService from '@alpegagroup/ui-core-shared/lib/shared/bundle/bundle-loader.service';
import { BundleUrlBuilder } from '@alpegagroup/ui-core-shared/lib/shared/bundle/bundle-url-builder';

export default class TermsConditionsDialogService {
  private static readonly APP_CHANGE_EVENT = 'single-spa:app-change';

  private static readonly UNLOAD_TERMS_CONDITIONS_APP_EVENT = 'alp.termsConditions.unloadApp';

  private static readonly DOM_ELEMENT_ID = 'terms-conditions-dialog';

  private static readonly SOURCE = 'teleroute';

  static instance: TermsConditionsDialogService;

  static getInstance(window: Window, bundleLoaderService: BundleLoaderService, bundleUrlBuilderService: BundleUrlBuilder) {
    if (!this.instance) {
      this.instance = new TermsConditionsDialogService(window, bundleLoaderService, bundleUrlBuilderService);
    }

    return this.instance;
  }

  appHasBeenLoadedOnce = false;
  termsConditionsApp: singleSpa.Parcel;

  constructor(
    private window: Window,
    private bundleLoaderService: BundleLoaderService,
    private bundleUrlBuilderService: BundleUrlBuilder) { }

  registerEvents(): void {
    this.window.addEventListener(TermsConditionsDialogService.APP_CHANGE_EVENT, async (event) => {
      if (this.appHasBeenLoadedOnce) {
        return;
      }

      if (!this.isOneMainAppLoaded(event as CustomEvent)) {
        return;
      }

      await this.loadApp();
    });

    this.window.addEventListener(TermsConditionsDialogService.UNLOAD_TERMS_CONDITIONS_APP_EVENT, () => {
      this.unloadApp();
    });
  }

  private isOneMainAppLoaded(event: CustomEvent): boolean {
    const appsByNewStatus = event.detail.appsByNewStatus;

    return appsByNewStatus.MOUNTED.some((mountedApp: string) => AppMain.MAIN_APPS.includes(mountedApp));
  }

  private async loadApp(): Promise<void> {
    this.appHasBeenLoadedOnce = true;

    const bundleUrl = await this.bundleUrlBuilderService.build(AppS3Folder.TERMS_CONDITIONS, AppS3Subfolder.NO_ROUTER_SPA);

    const termsConditionsBundle = await this.bundleLoaderService.load(bundleUrl);

    const parcelProps = {
      domElement: this.window.document.getElementById(TermsConditionsDialogService.DOM_ELEMENT_ID),
      source: TermsConditionsDialogService.SOURCE
    };

    this.termsConditionsApp = singleSpa.mountRootParcel(termsConditionsBundle.default, parcelProps);
  }

  private unloadApp(): void {
    if (this.termsConditionsApp) {
      this.termsConditionsApp.unmount();
      this.termsConditionsApp = null;
    }
  }
}
