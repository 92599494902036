import './polyfills';
import './styles/main.scss';

import BundleLoaderService from '@alpegagroup/ui-core-shared/lib/shared/bundle/bundle-loader.service';
import { EnvironmentCdnService } from '@alpegagroup/ui-core-shared/lib/shared/environment/EnvironmentCdnService';
import StyleguideLoaderService from '@alpegagroup/ui-core-shared/lib/shared/styleguide/styleguide-loader.service';

import AppOrchestrationConfig from './model/app-orchestration-config';
import AuthLoaderService from './service/auth/auth-loader.service';
import CompanyProfileLoaderService from './service/company-profile/company-profile-loader.service';
import AppRegisterService from './service/register/app-register.service';
import NonCacheAppRegisterService from './service/register/non-cache-app-register.service';
import RouteManagerService from './service/route/routing-manager.service';
import TermsConditionsDialogService from './service/terms-conditions/terms-conditions-dialog.service';
import BundleUrlBuilderService from '@alpegagroup/ui-core-shared/lib/shared/bundle/bundle-url-builder.service';
import LocalBundleUrlBuilderService from '@alpegagroup/ui-core-shared/lib/shared/bundle/local-bundle-url-builder.service';

// @ts-ignore
const isDev = IS_DEV;
const loadStyleGuideFromS3 = !isDev;

const environmentCdnService = EnvironmentCdnService.create();
const cdnUrl = environmentCdnService.resolveCdnUrl(window.location.hostname);

const bundleLoaderService = BundleLoaderService.getInstance(window);
const bundleUrlBuilderService = !isDev?
  BundleUrlBuilderService.getInstance(cdnUrl) :
  LocalBundleUrlBuilderService.getInstance(`http://${window.location.host}`);

const authLoaderService = AuthLoaderService.getInstance(window, bundleLoaderService, bundleUrlBuilderService);

const styleguideLoaderService = StyleguideLoaderService.getInstance(window);

authLoaderService.initAuth()
  .then(() => {
    TermsConditionsDialogService.getInstance(window, bundleLoaderService, bundleUrlBuilderService).registerEvents();

    CompanyProfileLoaderService.getInstance(window, bundleLoaderService, bundleUrlBuilderService).loadApp();

    styleguideLoaderService.loadStyleguide(loadStyleGuideFromS3);

    const registerService = new NonCacheAppRegisterService(bundleUrlBuilderService, new AppRegisterService(bundleLoaderService));

    registerService.register(AppOrchestrationConfig.SSPA_ROUTED_APPS);

    RouteManagerService.listenUrlChanges();
  })
  .catch((e) => {
    console.error(`APPLICATION CANNOT BE LOADED: ${e}`)
  });
