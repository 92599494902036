// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../webfonts/fontawesome-solid-900.eot";
import ___CSS_LOADER_URL_IMPORT_1___ from "../webfonts/fontawesome-solid-900.woff2";
import ___CSS_LOADER_URL_IMPORT_2___ from "../webfonts/fontawesome-solid-900.woff";
import ___CSS_LOADER_URL_IMPORT_3___ from "../webfonts/fontawesome-solid-900.ttf";
import ___CSS_LOADER_URL_IMPORT_4___ from "../webfonts/fontawesome-solid-900.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#fontawesome" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:'Font Awesome 5 Solid';font-style:normal;font-weight:900;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"svg\")}.fa,.fas{font-family:'Font Awesome 5 Solid';font-weight:900}\n", "",{"version":3,"sources":["webpack://./src/assets/fontawesome/css/font-awesome-solid.css"],"names":[],"mappings":"AAAA,WAAW,kCAAkC,CAAC,iBAAiB,CAAC,eAAe,CAAC,2CAAgD,CAAC,uSAAsV,CAAC,SAAS,kCAAkC,CAAC,eAAe","sourcesContent":["@font-face{font-family:'Font Awesome 5 Solid';font-style:normal;font-weight:900;src:url(\"../webfonts/fontawesome-solid-900.eot\");src:url(\"../webfonts/fontawesome-solid-900.eot?#iefix\") format(\"embedded-opentype\"),url(\"../webfonts/fontawesome-solid-900.woff2\") format(\"woff2\"),url(\"../webfonts/fontawesome-solid-900.woff\") format(\"woff\"),url(\"../webfonts/fontawesome-solid-900.ttf\") format(\"truetype\"),url(\"../webfonts/fontawesome-solid-900.svg#fontawesome\") format(\"svg\")}.fa,.fas{font-family:'Font Awesome 5 Solid';font-weight:900}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
