import { registerApplication, start } from 'single-spa';

import AppToLoad from '../../model/app-to-load';
import Registrable from './registrable';

import BundleLoaderService from '@alpegagroup/ui-core-shared/lib/shared/bundle/bundle-loader.service';

export default class AppRegisterService implements Registrable {
  constructor(private bundleLoaderService: BundleLoaderService) { }

  async register(apps: AppToLoad[]): Promise<void> {
    apps.forEach(app => {
      registerApplication({
        name: app.appName,
        activeWhen: app.activeWhen,
        app: () => this.bundleLoaderService.load(app.urlToLoad)
      });
    });

    start();
  }
}
