import { AppS3Folder } from '@alpegagroup/ui-core-shared/lib/constant/AppS3Folder';
import { AppS3Subfolder } from '@alpegagroup/ui-core-shared/lib/constant/AppS3Subfolder';
import BundleLoaderService from '@alpegagroup/ui-core-shared/lib/shared/bundle/bundle-loader.service';
import { BundleUrlBuilder } from '@alpegagroup/ui-core-shared/lib/shared/bundle/bundle-url-builder';

export default class AuthLoaderService {
  static instance: AuthLoaderService;

  static getInstance(window: Window, bundleLoaderService: BundleLoaderService, bundleUrlBuilderService: BundleUrlBuilder) {
    if (!this.instance) {
      this.instance = new AuthLoaderService(window, bundleLoaderService, bundleUrlBuilderService);
    }

    return this.instance;
  }

  async initAuth(): Promise<void> {
    const bundleUrl = await this.bundleUrlBuilderService.build(AppS3Folder.MARKETPLACE_AUTH, AppS3Subfolder.SPA);

    const authModule = await this.bundleLoaderService.load(bundleUrl);

    //@ts-ignore
    this.window.alp = this.window.alp || {};
    //@ts-ignore
    this.window.alp.authModule = authModule;

    //@ts-ignore
    return this.window.alp.authModule.initAuth();
  }

  constructor(
    private window: Window,
    private bundleLoaderService: BundleLoaderService,
    private bundleUrlBuilderService: BundleUrlBuilder) { }
}