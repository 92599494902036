import { GwtDeeplinkParam } from '../../../model/gwt-deeplink-param';
import { GwtDeeplinkFactoryService } from './gwt-deeplink-factory.service';

export default class GwtDeeplinkService {

  static isGwtDeeplink(urlParams: URLSearchParams): boolean {
    return urlParams.has(GwtDeeplinkParam.PLACE_NAME);
  }

  static redirectToOrchestratorUrl(window: Window, urlParams: URLSearchParams): void {
    const gwtDeeplinkStrategyFactory = GwtDeeplinkFactoryService.getInstance();

    const deeplink = gwtDeeplinkStrategyFactory.get(urlParams);

    if (deeplink) {
      window.location.href = deeplink.resolveUrl(window.location.origin);
    }
  }
}
