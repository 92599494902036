import { checkActivityFunctions, navigateToUrl } from 'single-spa';

import { AppActiveUrl } from '../../model/app-active-url';
import AppMain from '../../model/app-main';
import GwtDeeplinkService from './gwt-deeplink/gwt-deeplink.service';

export default class RoutingManagerService {

  private static readonly BEFORE_APP_CHANGE_EVENT = 'single-spa:before-app-change';

  private static readonly mainAppsActiveUrl = [
    AppActiveUrl.MARKETPLACE_DASHBOARD_UI,
    AppActiveUrl.FX_UI,
    AppActiveUrl.INSURANCE,
    AppActiveUrl.INVOICE_FACTORING,
    AppActiveUrl.INVOICE_FACTORING_PRAGMAGO,
    AppActiveUrl.TERMS_CONDITIONS,
  ];

  static listenUrlChanges() {
    window.addEventListener(RoutingManagerService.BEFORE_APP_CHANGE_EVENT, _ => {

      const urlParams = new URLSearchParams(window.location.search);

      if (GwtDeeplinkService.isGwtDeeplink(urlParams)) {
        GwtDeeplinkService.redirectToOrchestratorUrl(window, urlParams);
        return;
      }

      this.manageNotFoundResources();
    });
  }

  private static manageNotFoundResources() {
    const appsThatShouldBeActive = checkActivityFunctions(window.location);

    if (this.atLeastOneMainAppLoaded(appsThatShouldBeActive)) {
      return;
    }

    const currentActiveUrl = '/' + window.location.hash;

    if (this.isRootApp(currentActiveUrl)) {
      navigateToUrl(AppActiveUrl.MARKETPLACE_DASHBOARD_UI);
      return;
    }

    this.redirectToAppActiveUrl(currentActiveUrl);
  }

  private static redirectToAppActiveUrl(currentActiveUrl) {
    const validActiveUrl = RoutingManagerService.mainAppsActiveUrl.some(activeUrl => currentActiveUrl.indexOf(activeUrl) !== -1);

    validActiveUrl ? navigateToUrl(currentActiveUrl) : navigateToUrl(AppActiveUrl.MARKETPLACE_DASHBOARD_UI);
  }

  private static atLeastOneMainAppLoaded(appsThatShouldBeActive: string[]) {
    return AppMain.MAIN_APPS.some((mainApp: string) => appsThatShouldBeActive.indexOf(mainApp) !== -1);
  }

  private static isRootApp(currentActiveUrl) {
    return currentActiveUrl === '/';
  }
}
