import { BundleUrlBuilder } from '@alpegagroup/ui-core-shared/lib/shared/bundle/bundle-url-builder';
import AppToLoad from '../../model/app-to-load';
import Registrable from './registrable';

export default class NonCacheAppRegisterService implements Registrable {
  constructor(private bundleUrlService: BundleUrlBuilder, private registrable: Registrable) { }

  async register(apps: AppToLoad[]): Promise<void> {
    const appsToResolve = apps.map(async (app) => {
      const urlToLoad = await this.bundleUrlService.build(app.s3Folder, app.s3Subfolder);

      return {
        ...app,
        urlToLoad,
      };
    });

    const decoratedApps = await Promise.all(appsToResolve);

    await this.registrable.register(decoratedApps);
  }
}
