import { App } from './app';
import { AppActiveUrl } from './app-active-url';

import { AppS3Folder } from '@alpegagroup/ui-core-shared/lib/constant/AppS3Folder';
import { AppS3Subfolder } from '@alpegagroup/ui-core-shared/lib/constant/AppS3Subfolder';
import AppToLoad from './app-to-load';

export default class AppOrchestrationConfig {
  static readonly SSPA_ROUTED_APPS: AppToLoad[] = [
    {
      appName: App.CHAT,
      activeWhen: AppActiveUrl.CHAT,
      s3Folder: AppS3Folder.CHAT,
      s3Subfolder: AppS3Subfolder.SPA
    },
    {
      appName: App.TERMS_CONDITIONS,
      activeWhen: AppActiveUrl.TERMS_CONDITIONS,
      s3Folder: AppS3Folder.TERMS_CONDITIONS,
      s3Subfolder: AppS3Subfolder.SPA
    },
    {
      appName: App.MARKETPLACE_DASHBOARD_UI,
      activeWhen: AppActiveUrl.MARKETPLACE_DASHBOARD_UI,
      s3Folder: AppS3Folder.MARKETPLACE_DASHBOARD,
      s3Subfolder: AppS3Subfolder.SPA
    },
    {
      appName: App.MARKETPLACE_HEADER_UI,
      activeWhen: AppActiveUrl.MARKETPLACE_HEADER_UI,
      s3Folder: AppS3Folder.MARKETPLACE_HEADER,
      s3Subfolder: AppS3Subfolder.SPA
    },
    {
      appName: App.MARKETPLACE_FOOTER_UI,
      activeWhen: AppActiveUrl.MARKETPLACE_FOOTER_UI,
      s3Folder: AppS3Folder.MARKETPLACE_FOOTER,
      s3Subfolder: AppS3Subfolder.SPA
    },
    {
      appName: App.FX_UI,
      activeWhen: AppActiveUrl.FX_UI,
      s3Folder: AppS3Folder.FX_UI,
      s3Subfolder: AppS3Subfolder.SPA
    },
    {
      appName: App.INVOICE_FACTORING_PRAGMAGO,
      activeWhen: AppActiveUrl.INVOICE_FACTORING_PRAGMAGO,
      s3Folder: AppS3Folder.INVOICE_FACTORING_PRAGMAGO,
      s3Subfolder: AppS3Subfolder.SPA
    },
    {
      appName: App.INVOICE_FACTORING,
      activeWhen: AppActiveUrl.INVOICE_FACTORING,
      s3Folder: AppS3Folder.INVOICE_FACTORING,
      s3Subfolder: AppS3Subfolder.SPA
    },
    {
      appName: App.INSURANCE,
      activeWhen: AppActiveUrl.INSURANCE,
      s3Folder: AppS3Folder.INSURANCE,
      s3Subfolder: AppS3Subfolder.SPA
    },
    {
      appName: App.TELEROUTE_DIRECTORY,
      activeWhen: AppActiveUrl.TELEROUTE_DIRECTORY,
      s3Folder: AppS3Folder.TELEROUTE_DIRECTORY,
      s3Subfolder: AppS3Subfolder.SPA
    },
  ];

  static readonly CUSTOM_LOADED_APPS: AppToLoad[] = [
    {
      appName: App.MARKETPLACE_AUTH_UI,
      s3Folder: AppS3Folder.MARKETPLACE_AUTH,
      s3Subfolder: AppS3Subfolder.SPA
    },
    {
      appName: App.COMPANY_PROFILE,
      s3Folder: AppS3Folder.COMPANY_PROFILE,
      s3Subfolder: AppS3Subfolder.SPA
    },
    {
      appName: App.TERMS_CONDITIONS_NO_ROUTER,
      s3Folder: AppS3Folder.TERMS_CONDITIONS,
      s3Subfolder: AppS3Subfolder.NO_ROUTER_SPA
    },
  ]
}
