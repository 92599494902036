import { GwtDeeplinkStrategy } from './gwt-deeplink-strategy';
import { InsuranceUpdateDeeplinkStrategy } from './insurance-update-deeplink-strategy.service';
import { NoParamsDeeplinkStrategy } from './no-params-deeplink-strategy.service';

export class GwtDeeplinkFactoryService {

  private strategies: GwtDeeplinkStrategy[];

  static instance: GwtDeeplinkFactoryService;

  static getInstance() {
    if (!this.instance) {
      this.instance = new GwtDeeplinkFactoryService();
    }

    return this.instance;
  }

  constructor() {
    this.strategies = [
      new NoParamsDeeplinkStrategy(),
      new InsuranceUpdateDeeplinkStrategy(),
    ];
  }

  get(urlSearchParams: URLSearchParams): GwtDeeplinkStrategy {
    return this.strategies.find(strategy => strategy.matchDeeplink(urlSearchParams));
  }
}
