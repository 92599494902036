import * as singleSpa from 'single-spa';

import { AppS3Folder } from '@alpegagroup/ui-core-shared/lib/constant/AppS3Folder';
import { AppS3Subfolder } from '@alpegagroup/ui-core-shared/lib/constant/AppS3Subfolder';
import BundleLoaderService from '@alpegagroup/ui-core-shared/lib/shared/bundle/bundle-loader.service';
import { BundleUrlBuilder } from '@alpegagroup/ui-core-shared/lib/shared/bundle/bundle-url-builder';

export default class CompanyProfileLoaderService {

  private static readonly DOM_ELEMENT_ID = 'company-profile-parcel-root';

  static instance: CompanyProfileLoaderService;

  static getInstance(window: Window, bundleLoaderService: BundleLoaderService, bundleUrlBuilderService: BundleUrlBuilder) {
    if (!this.instance) {
      this.instance = new CompanyProfileLoaderService(window, bundleLoaderService, bundleUrlBuilderService);
    }

    return this.instance;
  }

  constructor(
    private window: Window,
    private bundleLoaderService: BundleLoaderService,
    private bundleUrlBuilderService: BundleUrlBuilder) { }

  async loadApp(): Promise<void> {
    const defaultProps = {
      domElement: this.window.document.getElementById(CompanyProfileLoaderService.DOM_ELEMENT_ID),
      renderRootEmpty: true
    }

    const bundleUrl = await this.bundleUrlBuilderService.build(AppS3Folder.COMPANY_PROFILE, AppS3Subfolder.SPA);

    const companyProfileBundle = await this.bundleLoaderService.load(bundleUrl);

    const companyProfileParcel = singleSpa.mountRootParcel(companyProfileBundle, defaultProps);

    this.window[ 'alpCompanyProfileParcel' ] = companyProfileParcel;
  }
}