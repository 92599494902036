export enum AppActiveUrl {
  CHAT = '/',
  TERMS_CONDITIONS = '/#/terms-conditions',
  MARKETPLACE_DASHBOARD_UI = '/#/dashboard',
  MARKETPLACE_HEADER_UI = '/',
  MARKETPLACE_FOOTER_UI = '/',
  FX_UI = '/#/fx-ui',
  INVOICE_FACTORING_PRAGMAGO = '/#/pragmago-invoice-factoring',
  INVOICE_FACTORING = '/#/invoice-factoring',
  INSURANCE = '/#/insurance',
  TELEROUTE_DIRECTORY = '/#/teleroute-directory',
  STYLEGUIDE = '/',
}
