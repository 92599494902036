import { App } from './app';

export default class AppMain {
  static readonly MAIN_APPS: string[] = [
    App.MARKETPLACE_DASHBOARD_UI,
    App.FX_UI,
    App.INSURANCE,
    App.INVOICE_FACTORING_PRAGMAGO,
    App.INVOICE_FACTORING,
    App.TERMS_CONDITIONS,
    App.TELEROUTE_DIRECTORY,
  ];
}
